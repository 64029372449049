// extracted by mini-css-extract-plugin
export var active = "Projects-module--active--HDv7T";
export var description = "Projects-module--description--vZ4AO";
export var icon_pencil = "Projects-module--icon_pencil--QMMTa";
export var icon_suitcase = "Projects-module--icon_suitcase--AXO-9";
export var project_card = "Projects-module--project_card--Z4xtK";
export var project_card__content = "Projects-module--project_card__content--m3b80";
export var project_card__controls = "Projects-module--project_card__controls--rZ2b+";
export var project_card__info = "Projects-module--project_card__info--MBbS9";
export var project_card_footer = "Projects-module--project_card_footer--7R1Tl";
export var project_card_image = "Projects-module--project_card_image--uNakb";
export var project_cards__container = "Projects-module--project_cards__container--rYwD6";
export var project_links = "Projects-module--project_links--lu40x";
export var projects__description = "Projects-module--projects__description--ucs27";
export var projects__nav = "Projects-module--projects__nav--0iIpU";
export var site_img = "Projects-module--site_img--Cv65O";